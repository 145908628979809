<template>
    <div
        id="aplayer"
        v-show="$store.state.player.playActive"
        :class="$store.state.player.playActive ? 'active' : ''"
    >
        <!-- http://xsd.fenhol.com/static/test.mp3 -->
        <video
            ref="audio"
            controls="controls"
            preload="auto"
            style="display: none"
            webkit-playsinline="true"
            playsinline="true"
            @play="onPlay"
            @pause="onPause"
            @timeupdate="onTimeupdate"
            @loadedmetadata="onLoadedmetadata"
        ></video>
        <!-- <audio
      ref="audio"
      preload="auto"
      @play="onPlay"
      @pause="onPause"
      @timeupdate="onTimeupdate"
      @loadedmetadata="onLoadedmetadata"
      :src="datas.current.audioHttp"
      controls="controls"
      style="display:none;"
    ></audio> -->
        <div class="aplayer-bar">
            <div
                class="aplayer-loaded"
                :style="'width:' + datas.silderNone + '%'"
            ></div>
            <div
                class="aplayer-played"
                :style="'width:' + datas.sliderTime + '%'"
            ></div>
        </div>
        <div
            class="random"
            :class="datas.random ? '' : 'active'"
            @click="onRandom()"
        ></div>
        <!-- 播放 暂停 -->
        <div
            v-if="!datas.play"
            class="palybtn iconfont iconsanjiaoxing"
            @click="startPlayOrPause()"
        ></div>
        <div
            v-else
            class="palybtn iconfont iconpause"
            @click="startPlayOrPause()"
        ></div>
        <div class="aplayer-center" @click="childrenshow()">
            <div class="aplayer-tatle">{{ datas.currenting.name }}</div>
            <div class="appname">{{ datas.currenting.dec }}</div>
        </div>
        <!-- <playhome
      ref="cplayhome"
      :datas="datas"
      @childrenhide="childrenhide"
      @startPlayOrPause="startPlayOrPause"
      @switchAudio="switchAudio"
      @onRandom="onRandom"
    ></playhome> -->
    </div>
</template>

<script>
/* eslint-disable */
import playhome from "@/pages/playhome";
// import Hls from "hls.js";
var times = null;
export default {
    components: {
        playhome,
    },
    data() {
        return {
            datas: {
                sliderTime: 0, //当前播放进度
                silderNone: 0, //模拟预加载
                audio: {
                    // 音频是否处于播放状态
                    playing: false,
                    // 音频当前播放时长
                    currentTime: 0,
                    // 音频最大播放时长
                    maxTime: 0,
                },
                play: false, // 播放暂停按钮
                random: true,
                children: false,
                rnum: 0, //播放随机
                currenting: {
                    poster: "",
                    name: "",
                    dec: "",
                    play_url: "",
                },
                current: [],
            },
        };
    },
    mounted() {},
    watch: {
        "$store.state.player.list"(v, old) {
            this.datas.current = v;
            if (old.length == 0 && !this.$store.state.player.current) {
                this.$store.dispatch("setCurrent", v[0]);
            }
            console.log("currentPlayerList", this.datas);
        },
        "$store.state.player.current"(v) {
            this.datas.currenting = v;
            console.log("currenting", this.datas);
        },
        currentItem() {
            this.initsource();
        },
        "$route.path"() {
            let router = this.$route;
            let showPlayer = router.meta.player_show || false;
            let isPlayIng = this.isPlayIng() || false;
            if (showPlayer && isPlayIng) {
                this.show();
            } else {
                this.hide();
            }
            console.log("路由变了", router);
        },
    },
    computed: {
        playList() {
            // 播放列表
            return this.datas.current || [];
        },
        currentItem() {
            // 当前播放的节目
            return this.datas.currenting || undefined;
        },
        currentIndex() {
            // 当前播放的节目[序号]
            let list = this.playList;
            let item = this.currentItem;
            if (!item) {
                return null;
            }
            let val = null;
            list.forEach((v, i) => {
                if (v.id == item.id) {
                    val = i;
                }
            });
            return val;
        },
    },
    methods: {
        show() {
            this.$store.state.player.playActive = true;
        },
        hide() {
            this.$store.state.player.playActive = false;
        },
        // 重新赋值列表
        setList(list = [], playAfterSet = false) {
            this.$store.state.player.list = list;
            if (playAfterSet && list.length > 0) {
                setTimeout(() => {
                    this.playItemByIndex(0);
                }, 100);
            }
        },
        // 列表中添加单个节目
        pushItem2List(item) {
            this.$store.state.player.list.push(item);
        },
        // 列表中添加多个节目
        pushList(list) {
            this.$store.state.player.list =
                this.$store.state.player.list.concat(list);
        },
        // 挂载资源
        async initsource(play = false) {
            if (!this.currentItem) {
                return false;
            }
            window.player = this.$refs.audio;
            let AudioSrc = this.currentItem.play_url;
            //   if(this.isIos()){
            this.$refs.audio.src = AudioSrc;

            // this.$refs.audio.onloadstart = () => {
            //     console.log("音频开始加载", arguments);
            //     // this.datas.audio.maxTime = Math.round(
            //     //     this.$refs.audio.duration
            //     // );
            // };

            this.$refs.audio.oncanplay = () => {
                console.log("音频加载完成", this.$refs.audio.duration);
                this.datas.audio.maxTime = Math.round(
                    this.$refs.audio.duration
                );
            };
            if (play) this.play();
            return false;
            //   }
            // let hls = new Hls();
            // // AudioSrc = 'https://media.aotuyuzhou.com/uploads/20200313/b0e2aff45d2380950dae485b8fd9116c.m3u8'
            // console.log("当前播放器", this.$refs.audio);
            // console.log("当前播放链接", AudioSrc);

            // hls.loadSource(AudioSrc);
            // hls.attachMedia(this.$refs.audio);
            // hls.on(Hls.Events.MANIFEST_PARSED, () => {
            //     setTimeout(() => {
            //         console.log(
            //             "音频加载完毕，时长:：",
            //             this.$refs.audio.duration
            //         );
            //         this.datas.audio.maxTime = Math.round(
            //             this.$refs.audio.duration
            //         );
            //     }, 400);
            //     if (play) this.play();
            // });
        },
        isIos() {
            return /(iphone|ipad|ipod|ios)/i.test(
                navigator.userAgent.toLowerCase()
            );
        },
        // 开始播放
        play() {
            console.log("当前播放器play", this.$refs.audio);
            this.$refs.audio.play();
        },
        // 暂停
        pause() {
            this.$refs.audio.pause();
        },
        // 循环播放
        onRandom() {
            if (this.datas.random) {
                this.datas.random = false;
            } else {
                this.datas.random = true;
            }
        },
        // 播放
        startPlayOrPause() {
            if (!this.datas.audio.playing) {
                this.play();
            } else {
                this.pause();
            }
        },
        // 当音频播放
        onPlay() {
            this.datas.audio.playing = true;
            this.datas.play = true;
        },
        // 当音频暂停
        onPause() {
            this.datas.audio.playing = false;
            this.datas.play = false;
            let startTime = parseInt(this.datas.audio.currentTime);
            if (startTime == parseInt(this.datas.audio.maxTime)) {
                console.log("当前播放完毕");
                // 自动播放下一首
                this.playNext();
            }
        },
        isPlayIng() {
            return this.datas.audio.playing;
        },
        // 资源加载完成时候
        onLoadedmetadata(res) {
            // this.datas.audio.maxTime = parseInt(res.target.duration);
        },
        // 播放进度条
        onTimeupdate(res) {
            this.datas.audio.currentTime = res.target.currentTime;
            this.datas.sliderTime = parseInt(
                (this.datas.audio.currentTime / this.datas.audio.maxTime) * 100
            );
        },
        /**
         * 切换歌曲事件
         */
        switchAudio(value) {
            // const that = this;
            if (value === "last") {
                this.playPre();
            } else if (value === "next") {
                this.playNext();
            }
        },
        // 上一首
        playPre() {
            let index = this.currentIndex;
            let list = this.playList;
            if (index < 1) {
                index = 0;
            } else {
                index--;
            }
            this.playItemByIndex(index);
        },
        // 下一首[到头的话，从头开始]
        playNext() {
            let index = this.currentIndex;
            let list = this.playList;
            let lastIndex = list.length - 1;
            if (index >= lastIndex) {
                index = 0;
            } else {
                index++;
            }
            this.playItemByIndex(index);
        },
        // 子级调出
        childrenshow() {
            // this.$store.state.children=true;
            this.$router.push({ path: "/playhome" });
        },
        childrenhide() {
            this.$store.state.children = false;
        },
        // 播放指定索引的节目
        playItemByIndex(index = 0) {
            let list = this.playList;
            let item = list[index] || undefined;
            this.playByItem(item);
        },
        // 播放指定ID的节目[节目需在列表中]
        playItemById(id) {
            console.log(id);
            let list = this.playList;
            let plan = undefined,
                planIndex = undefined;
            list.forEach((v, i) => {
                if (v.id == id) {
                    console.log("您想播放", i, v);
                    plan = v;
                    planIndex = i;
                }
            });
            this.playByItem(plan);
        },
        playByItem(item) {
            if (item) {
                if (!this.currentItem || this.currentItem.id != item.id) {
                    this.pause();
                    setTimeout(() => {
                        this.datas.currenting = item;
                        this.initsource(true);
                    }, 200);
                } else if (this.currentItem.id == item.id) {
                    this.play();
                }
            } else {
                return this.$toast("您想播放的节目不在播放列表");
            }
        },
        // 获取当前播放的节目对象
        getCurrentItem() {
            return Object.assign(this.currentItem, {
                currentTime: this.datas.audio.currentTime,
                totalTime: this.datas.audio.maxTime,
                sliderTime: this.datas.sliderTime,
                playing: this.datas.audio.playing,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/page/aplayer";
</style>
