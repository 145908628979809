<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
    <aplayer ref="player" />
  </div>
</template>
<script>
import Vue from "vue";
import aplayer from "@/components/aplayer";
export default {
  name: "",
  components: {
    aplayer
  },
  mounted() {
    Vue.prototype.$player = this.$refs.player;
  }
};
</script>
<style lang="scss">
@import "@/assets/css/base";
@import "@/assets/css/common";
@import "@/assets/font/font";
</style>
