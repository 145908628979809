<template>
  <div class="playhome">
    <div class="head" :class="{active:showAbs}">
      <img class="logo" src="../../static/img/logo.png" alt="凹凸宇宙"/>
      <div class="name">
        <h1 class="atyz">凹凸宇宙</h1>
        <p>为一切欢欣的灵魂</p>
      </div>
      <div class="download" @click="ondownload">下载 <span>APP</span></div>
    </div>
    <div class="playhome-center">
      <div class="switch">
        <img
          class="userimg"
          :src="current.image"
        />
        <div class="play-text" @click="ondownload">
          <h2 class="play-name">{{ current.name }}</h2>
          <p class="play-introduce">{{ current.dec }}</p>
          <div class="like"></div>
        </div>
        <div class="Mask"></div>
        <div class="playhome-play-btn">
          <div class="last-btn" @click="switchAudio('last')"></div>
          <!-- 播放 暂停 -->
          <div
            class="paly-btn "
            :class="!current.playing ? '' : 'active'"
            @click="startPlayOrPause()"
          ></div>
          <div class="next-btn" @click="switchAudio('next')"></div>
        </div>
      </div>
      <div class="playhome-play">
        <div class="aplayer-bar">
          <div
            class="aplayer-played"
            :style="'width:' + current.sliderTime + '%'"
          ></div>
          <div
            class="aplayer-played-icon"
            :style="'left:' + current.sliderTime + '%'"
          ></div>
        </div>
        <div class="playhome-time">
          <div class="currentTime">
            {{ current.currentTime | formatSecond }}
          </div>
          <div class="maxTime">{{ current.totalTime | formatSecond }}</div>
        </div>
        
      </div>
      <div class="more">
        <p>还有更多</p>
        <i class="iconfont iconFill1"></i>
      </div>
      <div class="program">
        <div class="mtitle clearfix">
          <img src="../../static/img/music.png" />
          <div class="fl">
            <h3>我们还有更多节目</h3>
            <h3>We have more fun</h3>
          </div>
        </div>
        <ul v-if="res.data">
          <li v-for="(item,i) in res.data.items" :key="i">
            <div class="music-li-img">
              <img v-lazy="item.image">
            </div>
            <div class="music-li-center">
              <div class="music-name">{{item.name}}</div>
              <p>{{item.play_count}}次播放</p>
            </div>
            <div class="play" v-if="item.is_vip==0" @click="onPlay(item.id)"></div>
            <div class="play vip" v-if="item.is_vip==1" @click="ondownload"></div>
          </li>
        </ul>
      </div>
      <div class="radio">
        <div class="mtitle clearfix">
          <img src="../../static/img/radio.png" />
          <div class="fl">
            <h3>你可以拥有更多乐趣</h3>
            <h3>You can have more fun</h3>
          </div>
        </div>
        <ul v-if="res.data">
          <li v-for="(item,i) in res.data.broad" :key="i" @click="ondownload">
            <div class="radio-li-baner">
                <img v-lazy="item.image">
              </div>
              <div class="radio-li-center">
                <h3 class="radio-li-title">{{item.name}}</h3>
                <p class="radio-li-text">{{item.desc.substring(0,30)+'...'}}</p>
                <p class="radio-li-num">共{{item.items_count}}个节目</p>
              </div>
          </li>
        </ul>
      </div>
      <div class="bottom">
        <img class="logo" src="../../static/img/logo.png" alt="凹凸宇宙"/>
        <div class="name">
          <h1 class="atyz">凹凸宇宙</h1>
          <p>为一切欢欣的灵魂</p>
        </div>
        <div class="download" @click="ondownload">下载 <span>APP</span></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
function realFormatSecond(second) {
  var secondType = typeof second;
  if (secondType === "number" || secondType === "string") {
    second = parseInt(second);

    var hours = Math.floor(second / 3600);
    second = second - hours * 3600;
    var mimute = Math.floor(second / 60);
    second = second - mimute * 60;
    // hours + ':' +
    if(hours==0){
      return ("0" + mimute).slice(-2) + ":" + ("0" + second).slice(-2);
    }else{
      return hours + ':' + ("0" + mimute).slice(-2) + ":" + ("0" + second).slice(-2);
    }
    
  } else {
    return "0:00:00";
  }
}
export default {
  data() {
    return {
      showAbs: true,
      datalist:[],
      pageNum:1,
      res:{},
    };
  },
  computed:{
    current(){
      return this.$player.getCurrentItem()
    }
  },
  methods: {
    startPlayOrPause() {
      this.$player.startPlayOrPause();
    },
    switchAudio(value) {
      // this.$emit("switchAudio", value);
      this.$player.switchAudio(value)
    },
    ondownload(){
      window.location.href=this.res.data.download;
    },
    handleScroll(){
      // 页面滚动距顶部距离
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var scroll = scrollTop - this.i;
      this.i = scrollTop;
      if(scrollTop<60){
        this.showAbs=true;
        return;
      }
      if(scroll<-10){
        console.log('up')
        this.showAbs=true;
      }else if(scroll>10){
        console.log('down')
        this.showAbs=false;
      }
    },
    getData() {
      if(this.$store.state.player.res){
        let res=this.$store.state.player.res;
        this.res=res;
        this.datalist = this.datalist.concat(res.data.items);
        this.$player.setList(this.datalist, false);
        return;
      }
      this.$store
        .dispatch("orderMusic", {
          play_type: "order",
          page: this.pageNum
        })
        .then(res => {
          this.res=res;
          this.datalist = this.datalist.concat(res.data.items);
          this.$player.setList(this.datalist, false);
        })
        .catch(e => {
          console.log(e);
        });
    },
    onPlay (id) {
      console.log("player", this.$player)
      if(this.$player.isPlayIng()){
        this.$player.pause()
      }else{
        this.$player.show();
        this.$player.playItemById(id)
      }
    },
  },
  mounted () {
    // document.body.scrollTop = 0;
    // document.documentElement.scrollTop = 0; 
    console.log(this.current)
    this.getData();
    this.$player.show()
    // scroll
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  filters: {
    //过滤器
    // 将整数转化成时分秒
    formatSecond(second = 0) {
      return realFormatSecond(second);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/css/page/playhome";
</style>
