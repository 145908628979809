import Server from "./server";

// 首页数据
export function homeData(data = {}) {
  return Server({
    url: "/api/v1.h5/home",
    method: "post",
    data: data
  });
}
