import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      player_show: true,
      keepAlive: true
    },
    component: resolve => require(["@/pages/index"], resolve)
  },
  {
    path: "/playhome",
    name: "playhome",
    meta: {
      player_show: false,
      keepAlive: true
    },
    component: resolve => require(["@/pages/playhome"], resolve)
  }
];

const router = new VueRouter({
  routes
});

export default router;
