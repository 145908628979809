import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import auth from "./utils/auth.js";
import { Toast, PullRefresh, List, Lazyload } from "vant";
import Util from "./utils/util.js";
import "vant/lib/index.css";
// import Vconsole from 'vconsole';

// const vConsole =new Vconsole();

// Vue.use(vConsole);


Vue.use(Toast)
  .use(PullRefresh)
  .use(List)
  .use(Lazyload, {
    error: require("../static/img/fmbj.png"),
    loading: require("../static/img/fmbj.png")
  });

Vue.prototype.$util = Util;
Vue.prototype.$auth = auth;
Vue.config.productionTip = false;
Vue.prototype.$appconf = {
  wechat: {
    appid: "wx665d61627d45247e"
  }
};
// Vue.prototype.$success = str => {
//   Toast.success(str);
// };

// Vue.prototype.$error = str => {
//   Toast.fail(str);
// };

// Vue.prototype.$loading = str => {
//   Toast.loading(str);
// };

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
