/* eslint-disable */
import { homeData } from '@/service/index'
export default {
    state: {
        // 播放列表
        res:false,
        list: [],
        // 播放过的列表
        played_list: [],
        // 播放模式， rand 随机 、order 顺序 、circle 单曲循环
        mode: "order",
        // 当前播放对象
        current: null,
        playActive:false,
        index:0
    },
    mutations: {
        PUT_LIST_TO_LIST: (state, data) => {
            // 向列表中添加多首歌曲
            state.list = state.list.concat(data);
        },
        PUT_ITEM_TO_LIST: (state, data) => {
            // 向列表中添加一首歌曲
            state.list.push(data);
        },
        PUT_ITEM_TO_PLAYED: (state, data) => {
            // 向播放过的列表中添加一首歌曲
            state.played_list.push(data);
        },
        SET_CURRENT: (state, data) => {
            // 设置当前播放的歌曲
            state.current = data
        },
        SET_PLAY_MODE: (state, mode) => {
            // 将播放类型设置为相应播放模式
            state.mode = mode
        },
        SET_RES: (state, res) => {
            // res
            state.res = res
        }
    },
    actions: {
        // 将播放类型设置为顺序播放
        setPlayModeOrder({commit}, {}){
            commit('SET_PLAY_MODE', "order")
        },
        // 将播放类型设置为随机播放
        setPlayModeRand({commit}, {}){
            commit('SET_PLAY_MODE', "rand")
        },
        // 将播放类型设置为循环播放
        setPlayModeCircle({commit}, {}){
            commit('SET_PLAY_MODE', "circle")
        },
        // 设置当前播放的曲目
        setCurrent({commit}, item){
            commit('SET_CURRENT', item)
        },
        // 添加一首歌
        putMusic({commit}, data){
            commit('PUT_ITEM_TO_LIST', data)
        },
        // 获取顺序数据
        orderMusic({
            commit
        }, data) {
            return new Promise((resolve, reject) => {
                homeData(data).then(response => {
                    const data = response.data
                    console.log("orderMusic", data)
                    commit('SET_RES', response);
                    // commit('PUT_LIST_TO_LIST',{
                    //     data: {
                    //         data: dataObj.data.concat(data.data),
                    //         last_page: data.last_page,
                    //         total: data.total,
                    //         total_page: data.last_page,
                    //         per_page: data.per_page,
                    //         current_page: data.current_page
                    //     }
                    // })
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 获取随机数据
        randMusic({
            commit
        }, data) {           
            return new Promise((resolve, reject) => {
                // 这里应该
                homeData(data).then(response => {
                    const data = response.data
                    console.log("randMusic", data)
                    commit('SET_APP_LIST',{
                        // data_index: data_index,
                        data: {
                            data: data.data.concat(data.data),
                            last_page: data.last_page,
                            total: data.total,
                            total_page: data.last_page,
                            per_page: data.per_page,
                            current_page: data.current_page
                        }
                    })
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}