/* eslint-disable */
import Cookie from 'js-cookie'

const formatTime = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()

    return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

const formatNumber = (n) => {
    n = parseInt(n).toString()
    return n[1] ? n : '0' + n
}

const hasOwn = (obj, type) => {
    return Object.prototype.hasOwnProperty.call(obj, type);
}

const isEmpty = (item) => {
    item = getString(item);
    if ("" == strim(item))
        return true;
    return false;
}

const isUndefined = item => {
    return typeof item === 'undefined';
}

const isDefined = item => {
    return !isUndefined(item);
}
const isString = item => {
    return typeof item === 'string';
}
const isNumber = item => {
    return typeof item === 'number';
}
const isArray = item => {
    return Object.prototype.toString.apply(item) === '[object Array]';
}
const isObject = item => {
    return typeof item === 'object' && !isArray(item);
}
const isFunction = item => {
    return typeof item === 'function';
}

const getString = (item, defaultStr) => {
    if (isString(item)) return item.trim();
    if (isNumber(item)) return `${item}`.trim();
    return defaultStr || '';
}
const getNumber = (item, defaultNum) => {
    var matches = getString(item).match(/\d+/);
    return isNumber(matches && +matches[0]) ? +matches[0] : defaultNum;
}
const getArray = (item, defaultArr) => {
    item = strToObject(item);
    return isArray(item) ? item : (defaultArr || []);
}
const getObject = (item, defaultObj) => {
    item = strToObject(item);
    return item ? item : (defaultObj || {});
}
const getFunction = (item) => {
    return isFunction(item) ? item : noop;
}

const isEqual = (x, y) => {
    if (x === y) {
        return true;
    }

    if (!(x instanceof Object) || !(y instanceof Object)) {
        return false;
    }

    if (x.constructor !== y.constructor) {
        return false;
    }

    for (var p in x) {
        if (x.hasOwnProperty(p)) {
            if (!y.hasOwnProperty(p)) {
                return false;
            }
            if (x[p] === y[p]) {
                continue;
            }
            if (typeof (x[p]) !== "object") {
                return false;
            }
            if (!Object.equals(x[p], y[p])) {
                return false;
            }
        }
    }

    for (p in y) {
        if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
            return false;
        }
    }
    return true;
}

const json = item => {
    let str = {
        type: Object.prototype.toString.call(item)
    }
    try {
        str = JSON.stringify(item)
    } catch (e) {
        str.error = e && e.stack || ''
    }
    return isString(str) ? str : $json(str)
}
const parse = item => {
    let obj = {
        type: Object.prototype.toString.call(item)
    }
    try {
        obj = JSON.parse(item)
    } catch (e) {
        obj.error = e && e.stack || ''
    }
    return isObject(obj) ? obj : $parse(obj)
}

const isPhone = (str) => {
    return /^1\d{10}$/.test(str)
}

const isEmail = (str) => {
    return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(str)
}

const strToObject = (str) => {
    var res = undefined;
    if (isObject(str))
        return str;
    try {
        res = JSON.parse(str);
    } catch (e) {

    }
    if (!res instanceof Object) {
        res = undefined;
    }
    return res;
}

const trim = function (str) {
    return str.replace(/(^\s*)|(\s*$)/g, '');
}
//删除左边的空格
const ltrim = function (str) {
    return str.replace(/(^\s*)/g, '');
}
//删除右边的空格
const rtrim = function (str) {
    return str.replace(/(\s*$)/g, '');
}
//去除所有空格
const strim = function (str) {
    return str.replace(/\s/g, '');
}
//字符串计算长度
const strlen = function (str) {
    var regch = /[\u4e00-\u9fa5]/;
    var length = 0;
    for (var i = 0; i < str.length; i++) {
        if (regch.test(str.charAt(i)) == true) {
            // 中文为2个字符
            length += 2;
        } else {
            // 英文一个字符
            length += 1;
        }
    }
    return length;
}

// 时间格式化
const timeformat = function (str, format, tissue) {
    var myDate = new Date();
    str = (str == 0 || !str || str == 'undefined' || str == NaN) ? myDate.getTime() : str;
    str = (typeof str == 'string') ? str.replace(/\-/g, '/') : str;
    (!format || format == '') ? format = 'y-m-d h:i:s': format;
    var nowstamp = parseInt(myDate.getTime() / 1000),
        itemtime = parseInt(str),
        year, m, d, h, i, s;
    if (format == 'timestamp') {
        (str === +str) ? str = parseInt(str): str = Date.parse(str);
        if (str > 100000000000) {
            str = str / 1000;
        }
        return str;
    }
    if (itemtime < 10000) {
        //普通时间直接转换
        itemtime = Date.parse(str);
    }
    if (itemtime > 100000000000) {
        itemtime = itemtime / 1000;
    }
    var timebe = nowstamp - itemtime;
    if (tissue && tissue > -1 && tissue < 864000 && timebe < tissue) {
        if (timebe > 86400) {
            //超过1天，按天计算
            return Math.floor(timebe / 86400) + '天前';
        } else if (timebe > 3600) {
            //超过1小时，按小时计算
            return Math.floor(timebe / 3600) + '小时前';
        } else if (timebe > 59) {
            //按分钟计算
            return Math.ceil(timebe / 60) + '分钟前';
        } else {
            return '刚刚';
        }
    } else {
        itemtime = myDate.setTime(itemtime * 1000);
        year = myDate.getFullYear();
        m = (myDate.getMonth() + 1 < 10) ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
        d = (myDate.getDate() < 10) ? '0' + (myDate.getDate()) : myDate.getDate();
        h = (myDate.getHours() < 10) ? '0' + (myDate.getHours()) : myDate.getHours();
        i = (myDate.getMinutes() < 10) ? '0' + (myDate.getMinutes()) : myDate.getMinutes();
        s = (myDate.getSeconds() < 10) ? '0' + (myDate.getSeconds()) : myDate.getSeconds();

        if ("object" == format.toLowerCase()) {
            return {
                year: year,
                mon: m,
                day: d,
                hour: h,
                min: i,
                sec: s
            };
        }

        return format.replace('y', year).replace('m', m).replace('d', d).replace('h', h).replace('i', i).replace('s', s);
    }
}

// 查看剩余时间
const leavetime = function (timeE, format) {
    timeE = timeformat(timeE || '2018-05-17 00:00:00', 'timestamp'); //结束时间
    format = format || "string";
    var now = timeformat(0, 'timestamp');
    var timec = timeE - now; // 时间差值
    var day = '',
        hour = '',
        min = '',
        sec = '',
        d = 0,
        h = 0,
        m = 0,
        s = 0;
    if (timec > 86400) {
        d = Math.floor(timec / 86400);
        day = d > 0 ? d + '天' : '';
        timec = timec % 86400;
    }
    if (timec > 3600) {
        h = Math.floor(timec / 3600);
        hour = h > 0 ? h + '小时' : '';
        timec = timec % 3600;
    }
    if (timec > 59) {
        m = Math.floor(timec / 60);
        min = m > 0 ? m + '分钟' : '';
        timec = Math.floor(timec % 60);
    }
    if (timec > 0) {
        sec = timec + "秒";
    }

    if ("object" == format.toLowerCase()) {
        return {
            day: formatNumber(d),
            hour: formatNumber(h),
            min: formatNumber(m),
            sec: formatNumber(timec),
        };
    }
    return day + hour + min + sec;
}

const TimeFormat = function (timec, format) {
    (!format || format == '') ? format = 'd天h小时i分钟s秒': format;
    var d = 0,
        h = 0,
        m = 0,
        s = 0;
    if (timec > 86400) {
        d = Math.floor(timec / 86400);
        timec = timec % 86400;
    }
    if (timec > 3600) {
        h = Math.floor(timec / 3600);
        timec = timec % 3600;
    }
    if (timec > 59) {
        m = Math.floor(timec / 60);
        timec = Math.floor(timec % 60);
    }
    if (timec > 0) {
        s = timec;
    }
    if ("object" == format.toLowerCase()) {
        return {
            day: d,
            hour: h,
            min: i,
            sec: s
        };
    }
    return format.replace('d', formatNumber(d)).replace('h', formatNumber(h)).replace('i', formatNumber(m)).replace('s', formatNumber(s));
}

const HTMLDecode = (str) => {
    var s = "";
    if (str.length == 0) return "";
    var ele = document.createElement('div')
    ele.innerHTML = str
    return ele.innerText || ele.textContent || '';
}

const isUrl = (str) => {
    return /^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i.test(str);
}

const page = () => {
    let pages = getCurrentPages() //获取加载的页面
    let currentPage = pages[pages.length - 1] //获取当前页面的对象
    let url = "/" == currentPage.route.substr(0, 1) ? currentPage.route : "/" + currentPage.route;
    return {
        pages: pages,
        current: currentPage,
        url: url,
        options: currentPage.options
    }
}

// 倒计时(正计时)
const Countdown = function (opt) {
    this.totalTime = opt.totalTime || 60; //总时间
    this.model = opt.model || "reduce"; // 计时方式（reduce倒计时、plus正计时）
    this.speed = opt.speed || 50; // 刷新速度 默认50毫秒一次
    this.setSec = opt.setSec || false; // 计时结果取秒
    this.start_callback = opt.start_callback || function () {}; // 计时开始回调函数
    this.callback = opt.callback || function () {}; // 计时中回调函数
    this.end_callback = opt.end_callback || function () {}; // 结束时回调函数

    this.init();
}
Countdown.prototype = {
    init: function () {
        var dateTime = new Date();
        this.start = dateTime.getTime();
        this.dirt = this.totalTime;
        this.start_callback();
        if ("reduce" == this.model) {
            this.timer();
        } else {
            this.timer_plus();
        }

    },
    timer: function () {

        var _this = this;
        this.the_timer = setInterval(function () {
            var now = new Date();

            if (_this.setSec) {
                _this.dirt = _this.totalTime - (Math.round((now.getTime() - _this.start) / 1000));
            } else {
                _this.dirt = _this.totalTime - now.getTime() + _this.start;
            }

            _this.callback(_this.dirt);

            if (0 >= _this.dirt) {
                clearInterval(_this.the_timer);
                _this.end_callback();
            }

        }, this.speed);
    },
    timer_plus: function () {
        var _this = this;
        this.the_timer = setInterval(function () {
            var now = new Date();

            if (_this.setSec) {
                _this.dirt = Math.round((now.getTime() - _this.start) / 1000);
            } else {
                _this.dirt = now.getTime() - _this.start;
            }

            _this.callback(_this.dirt);

            if (_this.totalTime <= _this.dirt) {
                clearInterval(_this.the_timer);
                _this.end_callback();
            }

        }, this.speed);
    }
}

const isWechat = () => {
    let ua = window.navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) == 'micromessenger';
}

const getQuery = name => {
    var reg = new RegExp(".*(^|\\#|\\?|\\&)" + name + "=([^\\&|\\#]*)(\\&|$)", 'i');
    var r = document.URL.match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}

const hasClass = (elem, cls) => {
    cls = cls || '';
    if (cls.replace(/\s/g, '').length == 0) return false; //当cls没有参数时，返回false
    return new RegExp(' ' + cls + ' ').test(' ' + elem.className + ' ');
}

const addClass = (ele, cls) => {
    if (!hasClass(ele, cls)) {
        ele.className = ele.className == '' ? cls : ele.className + ' ' + cls;
    }
}

const removeClass = (elem, cls) => {
    if (hasClass(elem, cls)) {
        var newClass = ' ' + elem.className.replace(/[\t\r\n]/g, '') + ' ';
        while (newClass.indexOf(' ' + cls + ' ') >= 0) {
            newClass = newClass.replace(' ' + cls + ' ', ' ');
        }
        elem.className = newClass.replace(/^\s+|\s+$/g, '');
    }
}

const IEVersion = () => {
    if ('undefined' == typeof navigator) {
        return false
    }
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串  
    var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器  
    var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器  
    var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
    if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
            return 7;
        } else if (fIEVersion == 8) {
            return 8;
        } else if (fIEVersion == 9) {
            return 9;
        } else if (fIEVersion == 10) {
            return 10;
        } else {
            return 6; //IE版本<=7
        }
    } else if (isEdge) {
        return 'edge'; //edge
    } else if (isIE11) {
        return 11; //IE11  
    } else {
        return -1; //不是ie浏览器
    }
}

const copyToClipboard = (str) => {
    try{
        var save = function (e){
            e.clipboardData.setData('text/plain',str);//下面会说到clipboardData对象
            e.preventDefault();//阻止默认行为
        }
        document.addEventListener('copy',save);
        var res = document.execCommand("copy");//使文档处于可编辑状态，否则无效
        if(res) return true
    }catch(e){
        console.log('复制error', e)
    }
    return false
}

export default {
    formatTime,
    formatNumber,
    hasOwn,
    isUndefined,
    isDefined,
    isString,
    isNumber,
    isArray,
    isObject,
    isFunction,
    getString,
    getNumber,
    getArray,
    getObject,
    getFunction,
    json,
    parse,
    isPhone,
    isWechat,
    isEmail,
    strToObject,
    trim,
    ltrim,
    rtrim,
    strim,
    strlen,
    leavetime,
    TimeFormat,
    timeformat,
    HTMLDecode,
    isUrl,
    isEmpty,
    isEqual,
    page,
    Countdown,
    getQuery,
    hasClass,
    addClass,
    removeClass,
    IEVersion,
    copyToClipboard,
    Cookie
}