/* eslint-disable */
import Cookie from 'js-cookie'
export function setUid(uid) {
  return Cookie.set("uid", uid);
}
export function getUid() {
  return Cookie.get("uid");
}
export function setUinfo(user_info) {
  return Cookie.set("user_info", JSON.stringify(user_info));
}
export function getUinfo() {
  var user_info = Cookie.get("user_info")
  return user_info ? JSON.parse(user_info) : undefined;
}
export function setToken(token) {
  return Cookie.set("token", token ? token : '');
}
export function getToken() {
  return Cookie.get("token");
}
export function isLogin() {
  var now = (new Date()).getTime() / 1000
  var token = getToken()
  var userInfo = getUinfo()
  console.table({
    token: token,
    userInfo: userInfo,
    expiretime: userInfo ? userInfo.expiretime : 0,
    now: now
  })  
  if( token && userInfo && userInfo.expiretime > now ) {
    return true
  }
  return false
}

export default {
  setUid,
  setUinfo,
  setToken,
  getToken,
  getUid,
  getUinfo,
  isLogin
}